import { isNil } from "~utils/value";
import { Device } from "../device/device.model";
import { PointType, PointValue } from "../point/point.model";

export class Alarm {
  id: number;
  name: string;
  device: Device;
  buildingName: string | null;
  description: string;
  type: PointType;
  unit: string | null;
  value?: PointValue;
  graphicLink?: string;
  stateTexts?: { label: string; value: number }[];
  alarmGroup: string | null;
  isActive?: boolean;
  isAcknowledged?: boolean | null;
  decimals: number | null;

  constructor(alarm: Alarm) {
    this.id = alarm.id;
    this.name = alarm.name;
    this.device = alarm.device;
    this.buildingName = alarm.buildingName || null;
    this.description = alarm.description;
    this.type = PointType.Alarm;
    this.graphicLink = alarm.graphicLink;
    this.unit = alarm.unit;
    this.value = alarm.value;
    this.stateTexts = alarm.stateTexts;
    this.alarmGroup = alarm.alarmGroup;
    this.isActive = alarm.isActive;
    this.isAcknowledged = alarm.isAcknowledged;
    this.decimals = alarm.decimals;
  }
}

export class AlarmWithFormatValue extends Alarm {
  constructor(alarm: Alarm) {
    super(alarm);
  }

  formatValue(): string {
    if (!this.value) {
      return "-";
    }

    if (this.value.label) {
      return this.value.label;
    }

    if (isNil(this.value.value)) {
      return "-";
    }

    const formattedValue = isNil(this.decimals)
      ? this.value.value
      : this.value.value.toFixed(this.decimals);

    if (this.unit) {
      return `${formattedValue} ${this.unit}`;
    }

    return formattedValue.toString();
  }
}
