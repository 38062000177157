import { fetchDeviceByExternalId } from "~device/device.queries";
import { Level } from "~level/level.model";
import { MenuItems } from "./graphics.model";

export const isExternalIdIncluded = (pointId: string) => pointId.includes(".");

export const findLevelById = (level: Level, id: number): Level | null => {
  if (level.id === id) return level;
  return level.parent ? findLevelById(level.parent, id) : null;
};

export const getBuildingIds = (level): number[] => {
  const levelIds = [level.id];

  if (level.parent) {
    const parentIds = getBuildingIds(level.parent);
    const buildingIds = parentIds.filter(
      (parentLevelId) => findLevelById(level, +parentLevelId)?.isBuilding,
    );
    levelIds.push(...buildingIds);
  }

  return levelIds;
};

export type DeviceGraphicResult = {
  deviceId: number;
  graphicId: number;
  crumbs: string[] | null;
};

export const findDeviceAndGraphicIdsByPath = async ({
  companyId,
  searchPath,
  externalId,
}: {
  searchPath: string;
  externalId: string;
  companyId: number;
}): Promise<DeviceGraphicResult | null> => {
  const device = await fetchDeviceByExternalId({ companyId, externalId });

  if (!device) {
    return null;
  }

  const graphic = device.graphics.find((graphic) =>
    searchInGraphicItems({ items: graphic.items, searchPath }),
  );

  if (graphic) {
    const crumbs = searchInGraphicItems({ items: graphic.items, searchPath });
    return {
      deviceId: device.id,
      graphicId: graphic.id,
      crumbs,
    };
  }

  return null;
};

export const searchInGraphicItems = ({
  items,
  searchPath,
  currentPath = [],
}: {
  items: MenuItems[];
  searchPath: string;
  currentPath?: string[];
}): string[] | null => {
  for (const item of items) {
    const crumbs =
      item.path === searchPath
        ? [...currentPath, item.name]
        : searchInGraphicItems({
            items: item.items || [],
            searchPath,
            currentPath: [...currentPath, item.name],
          });

    if (crumbs) {
      return crumbs;
    }
  }
  return currentPath;
};
