import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Trans } from "@lingui/macro";
import { styled } from "styled-components";

import {
  Icon,
  Button as MirandaButton,
  ScrollableContainer,
  Spinner,
  Text,
} from "@fidelix/fx-miranda";

import {
  Button,
  Dialog,
  DialogTrigger,
  OverlayTriggerStateContext,
  Popover,
} from "react-aria-components";

import { useNavigatonPath } from "~utils/url";
import { Alarm } from "./alarm.model";
import { useAlarmsCountQuery, useAlarmsQuery } from "./alarm.queries";
import { alarmBellLimit, alarmFetchLimit } from "../utils/consts";

function AlarmsMenu({
  alarms,
  isFetching,
  isLoading,
  isError,
}: {
  alarms: Alarm[];
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
}) {
  const navigate = useNavigate();
  const { to } = useNavigatonPath();
  const state = useContext(OverlayTriggerStateContext);

  return (
    <AlarmsPopover>
      <AlarmsMenuHeader>
        <Text variant="subtitle1">
          <Trans>Active Alarms</Trans>
        </Text>
        {isFetching && !isLoading && <Spinner />}
      </AlarmsMenuHeader>
      <AlarmsMenuBody>
        {alarms.length > 0 ? (
          alarms.map((alarm) => (
            <AlarmButton
              key={alarm.id}
              onPress={() => {
                state.close();
                navigate(to(`devices/${alarm.device.id}/points/${alarm.id}`));
              }}
            >
              <Text variant="body">{alarm.name}</Text>
              <Text variant="bodyLight">{alarm.buildingName}</Text>
            </AlarmButton>
          ))
        ) : isLoading ? (
          <EmptyContainer>
            <Spinner size="medium" />
          </EmptyContainer>
        ) : isError ? (
          <EmptyContainer>
            <Text variant="body">
              <Trans>Failed to load active alarms</Trans>
            </Text>
          </EmptyContainer>
        ) : (
          <EmptyContainer>
            <Text variant="body">
              <Trans>No active alarms</Trans>
            </Text>
          </EmptyContainer>
        )}
      </AlarmsMenuBody>
      <AlarmsMenuFooter>
        <MirandaButton
          variant="text"
          icon="chevronRight"
          iconPlacement="right"
          onPress={() => {
            state.close();
            navigate(to("alarms?tab=active&sort=value.lastUpdate&order=desc"));
          }}
        >
          <Text variant="bodyBold" color="darkPrimary">
            <Trans>Show All Active Alarms</Trans>
          </Text>
        </MirandaButton>
      </AlarmsMenuFooter>
    </AlarmsPopover>
  );
}

export function AlarmNotificationBell() {
  const { data: countData, isLoading: isCountLoading } = useAlarmsCountQuery();
  const {
    data = [],
    refetch,
    isLoading,
    isFetching,
    isError,
  } = useAlarmsQuery(
    {
      page: 1,
      pageSize: alarmFetchLimit,
      tab: "active",
    },
    // Set the enabled value for the actual data fetch to false initially, so the actual alarms data is only loaded
    // after the user clicks the bell icon.
    { refetchInterval: 30000, enabled: false },
  );

  const onOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      refetch();
    }
  };

  const count = countData?.count;

  return (
    <Container>
      <DialogTrigger onOpenChange={onOpenChange}>
        <BellButton>
          <Icon icon="bellFilled" color="white" size={32} />
        </BellButton>
        <Popover>
          <Dialog>
            <AlarmsMenu
              alarms={data}
              isFetching={isFetching}
              isLoading={isLoading}
              isError={isError}
            />
          </Dialog>
        </Popover>
        {isCountLoading ? (
          <SpinnerContainer>
            <Spinner size={24} />
          </SpinnerContainer>
        ) : (
          count &&
          count.active > 0 && (
            <NumberContainer>
              <Text variant="bodyExtraSmall" color="white">
                {count.active >= alarmBellLimit
                  ? `${alarmBellLimit}+`
                  : count.active}
              </Text>
            </NumberContainer>
          )
        )}
      </DialogTrigger>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
`;

const SmallInfoContainer = styled.div`
  aspect-ratio: 1 / 1;
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  pointer-events: none;
`;

const NumberContainer = styled(SmallInfoContainer)`
  background: ${(p) => p.theme.colors.error};
  border-radius: 50%;
  border: 1px solid ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.white};
  position: absolute;
  right: 0px;
`;

const SpinnerContainer = styled(SmallInfoContainer)`
  position: absolute;
  right: 0px;
  color: ${(p) => p.theme.colors.lightNeutral};
`;
const BellButton = styled(Button)`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    background: ${(p) => p.theme.colors.darkPrimary};
  }
`;

const AlarmsPopover = styled.div`
  width: 420px;
  position: absolute;
  background: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.lightNeutral};
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #0000001a;
  transform: translateX(-400px);
`;

const EmptyContainer = styled.div`
  width: 100%;
  display: flex;
  padding: ${(p) => p.theme.spacing.xlarge}px;
  justify-content: center;
`;

const AlarmButton = styled(Button)`
  width: 100%;
  display: flex;
  padding: ${(p) => p.theme.spacing.medium}px;
  gap: ${(p) => p.theme.spacing.xxsmall}px;
  flex-direction: column;
  &:hover {
    background: ${(p) => p.theme.colors.mutedNeutralBackground};
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${(p) => p.theme.colors.primaryMuted};
  }
`;

const AlarmsMenuBody = styled(ScrollableContainer)`
  max-height: 420px;
`;

const AlarmsMenuHeader = styled.div`
  padding: ${(p) => p.theme.spacing.medium}px;
  border-bottom: 1px solid ${(p) => p.theme.colors.primaryMuted};
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing.small}px;
`;

const AlarmsMenuFooter = styled.div`
  padding: ${(p) => p.theme.spacing.medium}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${(p) => p.theme.colors.primaryMuted};
`;
