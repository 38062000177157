import {
  IconButton,
  Menu,
  Stack,
  Tooltip,
  TreeView,
} from "@fidelix/fx-miranda";
import { useSpinDelay } from "spin-delay";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { Trans } from "@lingui/macro";
import { useParsedSearchParams } from "~utils/url";
import {
  useBuildingsSuspenseQuery,
  useLevelGraphicsQuery,
  useLevelsQuery,
} from "../level/level.queries";
import { useDevicesQuery } from "../device/device.queries";
import { Level } from "../level/level.model";
import { Device } from "../device/device.model";
import { useGraphicsQuery } from "./graphics.queries";
import { Graphics, MenuItems } from "./graphics.model";
import {
  getDeviceStatusColor,
  getDeviceStatusText,
} from "../device/device.utils";
import { useDefaultGraphicsQuery } from "./default-graphics.queries";
import { HasPermission } from "../utils/permission-helper";
import { Permissions } from "../const";
import { DeleteGraphics } from "./delete-graphics";
import { DownloadGraphics } from "./download-graphics";

export function DeviceTree() {
  const { data: levels = [] } = useBuildingsSuspenseQuery();

  return (
    <TreeView>
      {levels.map((level) => {
        return <DeviceItemTree key={level.id} level={level} isRoot />;
      })}
    </TreeView>
  );
}

function DeviceItemTree({
  level,
  isRoot = false,
  ...rest
}: {
  level: Level;
  isRoot?: boolean;
}) {
  const navigate = useNavigate();
  const { data: defaultGraphic } = useDefaultGraphicsQuery();
  const { parsedParams, setSearchParams } = useParsedSearchParams({
    openLevels: { parse: (p) => p.getAll("levelId") },
    graphicsId: { type: "string" },
  });

  const { data: devices = [], isLoading: isDevicesLoading } = useDevicesQuery({
    pageSize: Number.MAX_SAFE_INTEGER,
    page: 1,
    levelId: level.id,
  });

  const { data: levelGraphics = [] } = useLevelGraphicsQuery({
    levelId: level.id,
  });

  const { data: subLevels = [], isLoading: isSubLevelsLoading } =
    useLevelsQuery({
      levelId: level.id,
    });

  useEffect(() => {
    const { defaultGraphicsPath } = defaultGraphic || {};
    if (defaultGraphicsPath && !parsedParams.graphicsId) {
      navigate(defaultGraphicsPath);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = useSpinDelay(isDevicesLoading || isSubLevelsLoading);
  function onLevelOpenChange(isOpen: boolean) {
    const idString = String(level.id);
    setSearchParams((params) => {
      if (isOpen) {
        params.append("levelId", idString);
      } else {
        params.delete("levelId", idString);
      }
      return params;
    });
  }

  if (!isRoot && isLoading) {
    return <TreeView.Loader />;
  }

  return (
    <TreeView.Item
      label={level.name}
      icon={level.isBuilding ? "building" : undefined}
      onOpenChange={onLevelOpenChange}
      isDefaultOpen={parsedParams.openLevels.includes(String(level.id))}
      renderItem={(content) => (
        <HoverActions levelId={level.id}>{content}</HoverActions>
      )}
      {...rest}
    >
      {levelGraphics.length > 0 &&
        levelGraphics.map((graphic, index: number) => (
          <TreeView.Item
            label={graphic.name}
            key={`${graphic.name}-${graphic.level!.id}`}
            isDefaultOpen={+(parsedParams.graphicsId || "") === graphic.id}
            renderItem={(content) => (
              <HoverActions levelId={level.id} graphic={graphic}>
                {content}
              </HoverActions>
            )}
          >
            {(graphic.items || []).map((item) => (
              <MenuItem
                item={item}
                entityId={level.id}
                key={`${item.name}-${level.id}-${index}`}
                graphicsId={graphic.id}
                path={[graphic.name, item.name]}
                isLevel={true}
              />
            ))}
          </TreeView.Item>
        ))}
      {subLevels.length > 0 &&
        subLevels.map((subLevel) => (
          <DeviceItemTree level={subLevel} key={subLevel.id} />
        ))}

      {devices.map((device) => (
        <DeviceTreeItem
          device={device}
          key={device.id}
          graphicsId={device.graphics[0]?.id}
        />
      ))}
    </TreeView.Item>
  );
}

function DeviceTreeItem({
  device,
  graphicsId,
}: {
  device: Device;
  graphicsId?: number;
}) {
  const { parsedParams, setSearchParams } = useParsedSearchParams({
    openDevices: { parse: (p) => p.getAll("deviceId") },
    graphicsId: { type: "string" },
  });

  const { data: graphic } = useGraphicsQuery({
    graphicsId: graphicsId!,
    enabled: !!graphicsId,
  });

  function onDeviceOpenChange(isOpen: boolean) {
    const idString = String(device.id);
    setSearchParams((param) => {
      if (isOpen) {
        param.append("deviceId", idString);
      } else {
        param.delete("deviceId", idString);
      }
      return param;
    });
  }

  return (
    <TreeView.Item
      icon="statusSmall"
      iconColor={getDeviceStatusColor(device.status)}
      iconSize={32}
      label={device.name}
      key={device.id}
      onOpenChange={onDeviceOpenChange}
      isDefaultOpen={parsedParams.openDevices.includes(String(device.id))}
      // We have to manually render this node with the tooltip, otherwise the tooltip doesn't align correctly on the device node
      renderItem={(content) => {
        // We know content is a ReactElement hence casting to ReactElement to avoid type errors
        const { props, type } = content as ReactElement;
        return (
          <DeviceItemWrapper as={type}>
            <Tooltip
              content={getDeviceStatusText(device.status)}
              placement="left"
            >
              <TooltipTrigger>{...props.children}</TooltipTrigger>
            </Tooltip>
          </DeviceItemWrapper>
        );
      }}
    >
      {graphic?.items.map((item) => (
        <TreeView.Item
          label={item.name}
          key={`${item.name}-${graphic.device!.id}`}
          isDefaultOpen={true}
        >
          {item.items.map((subItem) => (
            <MenuItem
              item={subItem}
              graphicsId={graphic.id}
              entityId={device.id}
              isLevel={false}
              key={`${subItem.name}-${device.id}`}
              path={[item.name, subItem.name]}
            />
          ))}
        </TreeView.Item>
      ))}
    </TreeView.Item>
  );
}

function MenuItem({
  item,
  entityId,
  graphicsId,
  path,
  isLevel,
}: {
  graphicsId: number;
  item: MenuItems;
  entityId: number;
  path: string[];
  isLevel: boolean;
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onMenuLevelSelect = (
    entityId: number,
    graphicsId: number,
    graphicPath: string,
  ) => {
    searchParams.set("path", JSON.stringify(path));
    searchParams.set("graphic", graphicPath);
    searchParams.set("graphicsId", String(graphicsId));

    navigate({
      pathname: `${isLevel ? "levels" : "devices"}/${entityId}/menus`,
      search: createSearchParams(searchParams).toString(),
    });
  };

  const menuPath = searchParams.get("path") || "";
  const paramsGraphicsId = searchParams.get("graphicsId") || "";

  const nestedMenuItems =
    item.items.length > 0 &&
    item.items.map((m, index: number) => (
      <MenuItem
        item={m}
        graphicsId={graphicsId}
        entityId={entityId}
        key={`${m.name}-${entityId}-${index}`}
        path={[...path, m.name]}
        isLevel={isLevel}
      />
    ));

  return item.ignoreHeader ? (
    nestedMenuItems
  ) : (
    <TreeView.Item
      label={item.name}
      key={`${item.name}-${entityId}`}
      isDefaultOpen={true}
      isSelected={
        JSON.stringify(path) === menuPath &&
        graphicsId.toString() === paramsGraphicsId
      }
      onSelect={() => onMenuLevelSelect(entityId, graphicsId, item.path)}
    >
      {nestedMenuItems}
    </TreeView.Item>
  );
}

function HoverActions({
  children,
  levelId,
  graphic,
}: {
  children: ReactNode;
  levelId: number;
  graphic?: Graphics;
}) {
  const [isHovered, setHovered] = useState(false);
  const navigate = useNavigate();

  const { props, type } = children as ReactElement;

  return (
    <LevelHoverActionsWrapper
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      as={type}
    >
      {...props.children}
      <HasPermission permission={Permissions.SCADAGRAPHICS_EDIT}>
        <LevelHoverActionsContent>
          {isHovered && graphic && levelId ? (
            <Stack>
              <DownloadGraphics graphic={graphic} />
              <DeleteGraphics levelId={levelId} graphicsId={graphic.id} />
            </Stack>
          ) : (
            isHovered && (
              <Menu
                onAction={() => navigate(`levels/${levelId}/add-graphics`)}
                trigger={<IconButton icon="plus" size="small" />}
              >
                <Menu.Item id="connect">
                  <Trans>Add Graphic</Trans>
                </Menu.Item>
              </Menu>
            )
          )}
        </LevelHoverActionsContent>
      </HasPermission>
    </LevelHoverActionsWrapper>
  );
}

const DeviceItemWrapper = styled.div`
  display: flex;
`;

const TooltipTrigger = styled(Tooltip.Trigger)`
  display: flex;
  align-items: center;
`;

const LevelHoverActionsContent = styled.div`
  display: flex;
  margin-left: auto;
`;

const LevelHoverActionsWrapper = styled.div<{ $isSelected?: boolean }>`
  width: 100%;
  background-color: ${(p) =>
    p.$isSelected ? p.theme.colors.neutralBackground : "transparent"};
`;
