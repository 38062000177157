import { t } from "@lingui/macro";
import { PuckValue } from "~point/point.model";
import { getPointLockStateLabel } from "../point/point.utils";

export const pageSizes = [10, 20, 50];
export const defaultPageSize = 10;
export const lockStateFilterOptions = {
  0: getPointLockStateLabel(0),
  1: getPointLockStateLabel(1),
  2: getPointLockStateLabel(2),
};
export const invalidCharacters = /^[^<>&"'\\/:*?|]*$/;
export const commonDebounceDelay = 400;
// Maximum number of alarms shown in the bell icon
export const alarmBellLimit = 10;
// Maximum number of disconnected devices shown in the navbar notification icon
export const deviceNoticationLimit = 10;
// Maximum number of devices shown in the popover list when the user clicks the disconnected devices navbar icon
export const deviceNotificationListLimit = 20;
// TEMPORARY - set the maximum amount of alarms fetched in one query
export const alarmFetchLimit = 500;

export type QueryOptions = {
  enabled?: boolean;
  refetchInterval?: number;
};

export function getDefaultTableTexts() {
  return {
    activeFiltersText: t`Active filters:`,
    clearAllFiltersButtonText: t`CLEAR`,
    emptyResultsText: t`No results found`,
    filtersSelectedText: t`selected`,
    searchPlaceholderText: t`Search`,
  };
}

export const appEnvironments = ["localhost", "dev", "prod"] as const;

export type AppEnv = (typeof appEnvironments)[number];

const subdomain = window.location.hostname.split(".")[0] || "";
const subdomainParts = subdomain.split("-");
const envSuffix = subdomainParts[0] as AppEnv;
export const currentEnv = appEnvironments.includes(envSuffix)
  ? envSuffix
  : "prod";

export const isEqual = (
  prevEvent: PuckValue,
  currEvent: PuckValue,
): boolean => {
  return (
    // For performance comparing only updatable keys
    prevEvent.value === currEvent.value &&
    prevEvent.decimals === currEvent.decimals &&
    prevEvent.unit === currEvent.unit &&
    prevEvent.lockState === currEvent.lockState &&
    prevEvent.alarmState === currEvent.alarmState &&
    prevEvent.errorState === currEvent.errorState &&
    prevEvent.state === currEvent.state
  );
};
