import { IconButton } from "@fidelix/fx-miranda";
import JSZip from "jszip";

import { useGraphicsAttachmentsQuery } from "./graphics.queries";

export function DownloadGraphics({ graphic }) {
  const { data: attachments } = useGraphicsAttachmentsQuery(graphic.id, {
    enabled: !!graphic.id,
  });

  const handleDownloadFile = async () => {
    if (!attachments?.length) {
      return;
    }
    try {
      const zip = new JSZip();
      for (const attachment of attachments) {
        if (!attachment.publicUrl || !attachment.name) {
          continue;
        }
        const response = await fetch(attachment.publicUrl);
        if (!response.ok) {
          continue;
        }
        const fileBlob = await response.blob();
        zip.file(attachment.name, fileBlob);
      }
      const content = await zip.generateAsync({ type: "blob" });
      const graphicsFile = document.createElement("a");
      graphicsFile.href = URL.createObjectURL(content);
      graphicsFile.download = `${graphic.name}.zip`;
      graphicsFile.click();
      URL.revokeObjectURL(graphicsFile.href);
    } catch (_err) {
      return undefined;
    }
  };

  return (
    <IconButton
      size="small"
      variant="filled"
      icon="download"
      onPress={handleDownloadFile}
    />
  );
}
